// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap")
//require("bootstrap/dist/js/bootstrap")
require("./vendors/trix_attachments.js")
require("./vendors/hs.megamenu.js")
require("./vendors/dzsparallaxer.js")

require("./vendors/scroller.js")
require("./vendors/plugin.js")
require("./vendors/slick.js")
require("./vendors/jquery.cubeportfolio.js")
require("./vendors/hs-bg-video.js")
require("./vendors/player.min.js")

require("./vendors/jquery.fancybox.js")


require("./vendors/hs.core.js")
require("./vendors/hs.header.js")
require("./vendors/hs.hamburgers.js")
require("./vendors/hs.tabs.js")
require("./vendors/hs.sticky-block.js")
require("./vendors/hs.carousel.js")
require("./vendors/hs.cubeportfolio.js")
require("./vendors/hs.bg-video.js")
require("./vendors/hs.popup.js")
require("./vendors/hs.counter.js")
require("./vendors/hs.progress-bar.js")
require("./vendors/hs.go-to.js")

require("./init.js")
require("./homes.js")
require("./posts.js")
require("./events.js")
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
