$(document).on('turbolinks:load', function() {
    if (!(document.page.controller() === 'homes' && document.page.action() === 'about_us'))
      return;
    // initialization of carousel
    $.HSCore.components.HSCarousel.init('.js-carousel');

    // initialization of tabs
    $.HSCore.components.HSTabs.init('[role="tablist"]');

    // initialization of counter
    var counters = $.HSCore.components.HSCounter.init('[class*="js-counter"]');

    // initialization of go to
    $.HSCore.components.HSGoTo.init('.js-go-to');

    // initialization of header
    $.HSCore.components.HSHeader.init($('#js-header'));
    $.HSCore.helpers.HSHamburgers.init('.hamburger');

    // initialization of HSMegaMenu component
    $('.js-mega-menu').HSMegaMenu({
      event: 'hover',
      pageContainer: $('.container'),
      breakpoint: 991
    });

    // initialization of vertical progress bar
    setTimeout(function () { // important in this case
      var verticalProgressBars = $.HSCore.components.HSProgressBar.init('.js-vr-progress-bar', {
        direction: 'vertical',
        indicatorSelector: '.js-vr-progress-bar-indicator'
      });
    }, 1);

    $(window).on('load', function () {
      // initialization of header
      $.HSCore.components.HSHeader.init($('#js-header'));
      $.HSCore.helpers.HSHamburgers.init('.hamburger');

      // initialization of HSMegaMenu component
      $('.js-mega-menu').HSMegaMenu({
        event: 'hover',
        pageContainer: $('.container'),
        breakpoint: 991
      });

      // initialization of vertical progress bar
      setTimeout(function () { // important in this case
        var verticalProgressBars = $.HSCore.components.HSProgressBar.init('.js-vr-progress-bar', {
          direction: 'vertical',
          indicatorSelector: '.js-vr-progress-bar-indicator'
        });
      }, 1);
    });

    $(window).on('resize', function () {
      setTimeout(function () {
        $.HSCore.components.HSTabs.init('[role="tablist"]');
      }, 200);
    });
});

$(document).on('turbolinks:load', function() {
    if (!(document.page.controller() === 'homes' && document.page.action() === 'clutch_production'))
      return;
    // initialization of carousel
    $.HSCore.components.HSCarousel.init('.js-carousel');

    // initialization of tabs
    $.HSCore.components.HSTabs.init('[role="tablist"]');

    // initialization of go to
    $.HSCore.components.HSGoTo.init('.js-go-to');

    // initialization of video on background
    $.HSCore.helpers.HSBgVideo.init('.js-bg-video');

    // initialization of popups
    $.HSCore.components.HSPopup.init('.js-fancybox');

    // initialization of header
    // $.HSCore.components.HSHeader.init($('#js-header'));
    $.HSCore.helpers.HSHamburgers.init('.hamburger');

    // initialization of HSMegaMenu component
    $('.js-mega-menu').HSMegaMenu({
      event: 'hover',
      pageContainer: $('.container'),
      breakpoint: 991
    });

    // initialization of cubeportfolio
    if (document.page.action() === 'clutch_production')
      $.HSCore.components.HSCubeportfolio.init('.cbp');

    $(window).on('load', function () {
      // initialization of header
      // $.HSCore.components.HSHeader.init($('#js-header'));
      $.HSCore.helpers.HSHamburgers.init('.hamburger');

      // initialization of HSMegaMenu component
      $('.js-mega-menu').HSMegaMenu({
        event: 'hover',
        pageContainer: $('.container'),
        breakpoint: 991
      });

      // initialization of cubeportfolio
      //$.HSCore.components.HSCubeportfolio.init('.cbp');
    });

    $(window).on('resize', function () {
      setTimeout(function () {
        $.HSCore.components.HSTabs.init('[role="tablist"]');
      }, 200);
    });
});

$(document).on('turbolinks:load', function() {
  $.HSCore.components.HSHeader.init($('#js-header'));
  // initialization of HSMegaMenu component
  $('.js-mega-menu').HSMegaMenu({
    event: 'hover',
    pageContainer: $('.container'),
    breakpoint: 991
  });
  if (!(document.page.controller() === 'homes' && document.page.action() === 'index'))
    return;
  // initialization of tabs
  $.HSCore.components.HSTabs.init('[role="tablist"]');

  // initialization of popups
  $.HSCore.components.HSPopup.init('.js-fancybox');

  // initialization of go to
  $.HSCore.components.HSGoTo.init('.js-go-to');

  $.HSCore.components.HSHeader.init($('#js-header'));
  $.HSCore.helpers.HSHamburgers.init('.hamburger');
  $('.js-mega-menu').HSMegaMenu({
    event: 'hover',
    pageContainer: $('.container'),
    breakpoint: 991
  });

  $.HSCore.components.HSCubeportfolio.init('.cbp');


  $(window).on('load', function () {
    // initialization of header
    $.HSCore.helpers.HSHamburgers.init('.hamburger');

    // initialization of HSMegaMenu component
    $('.js-mega-menu').HSMegaMenu({
      event: 'hover',
      pageContainer: $('.container'),
      breakpoint: 991
    });

    // initialization of cubeportfolio
    //$.HSCore.components.HSCubeportfolio.init('.cbp');
  });

  $(window).on('resize', function () {
    setTimeout(function () {
      $.HSCore.components.HSTabs.init('[role="tablist"]');
    }, 200);
  });
});
