$(document).on('turbolinks:load', function() {
    if (!(document.page.controller() === 'posts' && document.page.action() === 'show'))
      return;

    $.HSCore.components.HSStickyBlock.init('.js-sticky-block');

    // initialization of carousel
    $.HSCore.components.HSCarousel.init('.js-carousel');

    // initialization of tabs
    $.HSCore.components.HSTabs.init('[role="tablist"]');

    // initialization of popups with media
    $.HSCore.components.HSPopup.init('.js-fancybox');

    // initialization of go to
    $.HSCore.components.HSGoTo.init('.js-go-to');

    setTimeout(function() {
      $.HSCore.components.HSStickyBlock.init('.js-sticky-block');
    }, 300);

    $(window).on('load', function () {
      // initialization of header
      $.HSCore.components.HSHeader.init($('#js-header'));
      $.HSCore.helpers.HSHamburgers.init('.hamburger');

      // initialization of HSMegaMenu component
      $('.js-mega-menu').HSMegaMenu({
        event: 'hover',
        pageContainer: $('.container'),
        breakpoint: 991
      });

      // initialization of sticky blocks
      setTimeout(function() {
        $.HSCore.components.HSStickyBlock.init('.js-sticky-block');
      }, 300);
    });

    $(window).on('resize', function () {
      setTimeout(function () {
        $.HSCore.components.HSTabs.init('[role="tablist"]');
      }, 200);

      setTimeout(function() {
        $.HSCore.components.HSStickyBlock.init('.js-sticky-block');
      }, 300);
    });
});
