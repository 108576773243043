$(document).on('turbolinks:load', function() {
    if (!(document.page.controller() === 'events' && document.page.action() === 'show'))
      return;
    // initialization of carousel
    $.HSCore.components.HSCarousel.init('.js-carousel');
    // initialization of tabs
    $.HSCore.components.HSTabs.init('[role="tablist"]');
    // initialization of scroll animation
    //$.HSCore.components.HSOnScrollAnimation.init('[data-animation]');
    // initialization of go to
    $.HSCore.components.HSGoTo.init('.js-go-to')
});